export default function OtherArticles() {
    return (
        <>



            <div className="max-w-5xl px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">        
                  <h2 className=" font-bold md:leading-tight dark:text-white p-6">
                Autres articles a découvrir
            </h2>
                <div className="flex flex-col gap-6">
                    <a className="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-neutral-900 dark:border-neutral-800" href="#">
                        <div className="p-4 md:p-5">
                            <div className="flex">
                                <div className="grow ms-5">
                                    <h3 className="group-hover:text-blue-600 font-semibold text-gray-800 dark:group-hover:text-neutral-400 dark:text-neutral-200">
                                    Isolation des combles
                                    </h3>
                                    <p className="text-sm text-gray-500 dark:text-neutral-500">
                                        
                                    Guide exhaustif pour une rénovation performante
                                    </p>
                                </div>
                            </div>
                        </div>
                    </a>

                    <a className="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-neutral-900 dark:border-neutral-800" href="#">
                        <div className="p-4 md:p-5">
                            <div className="flex">
                                <div className="grow ms-5">
                                    <h3 className="group-hover:text-blue-600 font-semibold text-gray-800 dark:group-hover:text-neutral-400 dark:text-neutral-200">
                                    Couverture complete
                                    </h3>
                                    <p className="text-sm text-gray-500 dark:text-neutral-500">
                                        
                                    Guide exhaustif pour une rénovation performante
                                    </p>
                                </div>
                            </div>
                        </div>
                    </a>

                    <a className="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-neutral-900 dark:border-neutral-800" href="#">
                        <div className="p-4 md:p-5">
                            <div className="flex">
                                <div className="grow ms-5">
                                    <h3 className="group-hover:text-blue-600 font-semibold text-gray-800 dark:group-hover:text-neutral-400 dark:text-neutral-200">
                                        Dessous de toit en PVC
                                    </h3>
                                    <p className="text-sm text-gray-500 dark:text-neutral-500">
                                        Un guide détaillé pour fixer les dessous de toit en PVC
                                    </p>
                                </div>
                            </div>
                        </div>
                    </a>

                    <a className="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-neutral-900 dark:border-neutral-800" href="#">
                        <div className="p-4 md:p-5">
                            <div className="flex">
                                <div className="grow ms-5">
                                    <h3 className="group-hover:text-blue-600 font-semibold text-gray-800 dark:group-hover:text-neutral-400 dark:text-neutral-200">
                                    Nettoyage et entretien de votre couverture
                                    </h3>
                                    <p className="text-sm text-gray-500 dark:text-neutral-500">
                                    guide complet et conseils pour une intervention professionnelle  </p>
                                </div>
                            </div>
                        </div>
                    </a>
                    <a className="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-neutral-900 dark:border-neutral-800" href="#">
                        <div className="p-4 md:p-5">
                            <div className="flex">
                                <div className="grow ms-5">
                                    <h3 className="group-hover:text-blue-600 font-semibold text-gray-800 dark:group-hover:text-neutral-400 dark:text-neutral-200">
                                    Changement des gouttières aluminium
                                    </h3>
                                    <p className="text-sm text-gray-500 dark:text-neutral-500">
                                    guide complet et conseils pour unc changement en toute sérénité. </p>
                                </div>
                            </div>
                        </div>
                    </a>
                    <a className="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-neutral-900 dark:border-neutral-800" href="#">
                        <div className="p-4 md:p-5">
                            <div className="flex">
                                <div className="grow ms-5">
                                    <h3 className="group-hover:text-blue-600 font-semibold text-gray-800 dark:group-hover:text-neutral-400 dark:text-neutral-200">
                                    Traitement de charpente
                                    </h3>
                                    <p className="text-sm text-gray-500 dark:text-neutral-500">
                                    Le traitement curatif consiste à pénétrer en profondeur dans le bois, renforçant ainsi sa résistance et sa longévité.  </p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </>
    );
}