import OtherArticles from "./OtherArticles";

export default function UnderRoofing() {
    return (
        <>
            <div className="max-w-3xl px-4 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 mx-auto">
                <article className="prose prose-slate max-w-2xl">

                    <h2 className="text-2xl font-bold md:text-3xl dark:text-white"><span className="font-bold">Fixer les dessous de toit en PVC : un guide d&eacute;taill&eacute;</span></h2>
                    <h3  className="font-bold dark:text-white"><span className="font-bold">Introduction</span></h3>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span >La pose de dessous de toit en PVC est une op&eacute;ration relativement simple qui peut &ecirc;tre r&eacute;alis&eacute;e par un bricoleur amateur. Cependant, elle n&eacute;cessite une certaine pr&eacute;cision et un savoir-faire pour garantir un r&eacute;sultat &eacute;tanche et esth&eacute;tique.</span></p>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span className="font-bold">Mat&eacute;riel n&eacute;cessaire</span></p>
                    <ul>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span >Des dessous de toit en PVC</span></p>
                        </li>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span >Des liteaux en bois</span></p>
                        </li>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span >Des vis &agrave; bois</span></p>
                        </li>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span >Des chevilles</span></p>
                        </li>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span >Un niveau &agrave; bulle</span></p>
                        </li>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span >Une scie circulaire</span></p>
                        </li>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span >Une perceuse</span></p>
                        </li>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span >Un marteau</span></p>
                        </li>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span >Un m&egrave;tre ruban</span></p>
                        </li>
                    </ul>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><br /></p>
                    <h3 className="font-bold dark:text-white"><span className="font-bold">&Eacute;tapes de pose</span></h3>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span className="font-bold">1. Pr&eacute;paration</span></p>
                    <ul>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span >Commencez par nettoyer la surface du toit et enlever toutes les salet&eacute;s et d&eacute;bris.</span></p>
                        </li>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span >Installez les liteaux en bois sur le toit en les fixant aux chevrons &agrave; l&apos;aide de vis &agrave; bois. Veillez &agrave; ce que les liteaux soient bien de niveau.</span></p>
                        </li>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span >D&eacute;coupez les dessous de toit en PVC aux dimensions souhait&eacute;es &agrave; l&apos;aide d&apos;une scie circulaire.</span></p>
                        </li>
                    </ul>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span className="font-bold">2. Pose des dessous de toit</span></p>
                    <ul>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span >Placez le premier panneau de PVC contre un mur et fixez-le aux liteaux &agrave; l&apos;aide de vis &agrave; bois.</span></p>
                        </li>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span >Assurez-vous que le panneau est bien de niveau et qu&apos;il s&apos;embo&icirc;te correctement avec le mur.</span></p>
                        </li>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span >Continuez de fixer les panneaux de PVC un par un, en les embo&icirc;tant les uns dans les autres.</span></p>
                        </li>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span >Coupez le dernier panneau de PVC si n&eacute;cessaire et fixez-le en place.</span></p>
                        </li>
                    </ul>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span className="font-bold">3. Finitions</span></p>
                    <ul>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span >Appliquez un joint silicone au niveau des joints entre les panneaux de PVC pour assurer l&apos;&eacute;tanch&eacute;it&eacute;.</span></p>
                        </li>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span >Coupez les exc&eacute;dents de silicone &agrave; l&apos;aide d&apos;un cutter.</span></p>
                        </li>
                    </ul>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span className="font-bold">Conseils</span></p>
                    <ul>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span >Portez des lunettes de protection et des gants lors de la manipulation des outils.</span></p>
                        </li>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span >Utilisez une scie circulaire &eacute;quip&eacute;e d&apos;une lame de carbure pour couper les panneaux de PVC.</span></p>
                        </li>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span >Assurez-vous que les panneaux de PVC sont bien embo&icirc;t&eacute;s les uns dans les autres pour &eacute;viter les fuites d&apos;eau.</span></p>
                        </li>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span >Si vous n&apos;&ecirc;tes pas s&ucirc;r de vos comp&eacute;tences, il est pr&eacute;f&eacute;rable de faire appel &agrave; un professionnel pour la pose de vos dessous de toit en PVC.</span></p>
                        </li>
                    </ul>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><br /></p>
                    <h3 className="font-bold dark:text-white"><span className="font-bold">Faire appel &agrave; un professionnel</span></h3>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span >La pose de dessous de toit en PVC peut sembler &ecirc;tre une t&acirc;che simple, mais elle n&eacute;cessite en r&eacute;alit&eacute; une certaine expertise et un savoir-faire. Si vous n&apos;&ecirc;tes pas s&ucirc;r de vos comp&eacute;tences ou si vous n&apos;avez pas le temps de r&eacute;aliser la pose vous-m&ecirc;me, il est pr&eacute;f&eacute;rable de faire appel &agrave; un professionnel. Un couvreur exp&eacute;riment&eacute; sera en mesure de poser vos dessous de toit rapidement et efficacement, et vous garantira un r&eacute;sultat impeccable.</span></p>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><br /></p>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span className="font-bold">En faisant appel &agrave; un professionnel, vous vous assurez que:</span></p>
                    <ul>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span >La pose de vos dessous de toit sera r&eacute;alis&eacute;e conform&eacute;ment aux normes de construction en vigueur.</span></p>
                        </li>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span >Votre toiture sera &eacute;tanche et prot&eacute;g&eacute;e contre les intemp&eacute;ries.</span></p>
                        </li>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span >Vous aurez la tranquillit&eacute; d&apos;esprit de savoir que le travail a &eacute;t&eacute; bien fait.</span></p>
                        </li>
                    </ul>

                    <p className="text-lg text-gray-800 dark:text-gray-200"><br /></p>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span className="font-bold">N&apos;h&eacute;sitez pas &agrave; contacter notre entreprise de toiture pour obtenir un devis gratuit pour la pose de vos dessous de toit en PVC.</span></p>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><br /></p>
                </article>
            </div>

            
            <OtherArticles />
        </>
    );
}