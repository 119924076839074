const Testimonials = () => {
  return (
    <>
      <div className="relative max-w-[85rem]  px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">

        <div className="max-w-2xl mx-auto text-center pb-8">
          <h2 className="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white">
            Ce qu'ils en pensent
          </h2>
          <p className="mt-1 text-gray-600 dark:text-gray-400">
            Vos avis comptent!
          </p>
        </div>

        <div
          data-hs-carousel='{
    "loadingClassNamees": "opacity-0"
  }'
          className="relative"
        >
          <div className="hs-carousel relative overflow-hidden w-full min-h-[250px] py-10 rounded-lg">
            <div className="hs-carousel-body absolute top-0 bottom-0 start-0 flex flex-nowrap transition-transform duration-700 opacity-0">
              <div className="hs-carousel-slide dark:bg-slate-900">
                <blockquote className="text-center lg:mx-auto lg:w-3/5">
                  <div className="mt-6 lg:mt-10">
                    <p className="relative text-xl sm:text-2xl md:text-3xl md:leading-normal font-medium text-gray-800">
                      <svg
                        className="absolute top-0 start-0 transform -translate-x-8 -translate-y-8 h-16 w-16 text-gray-200 sm:h-24 sm:w-24 dark:text-gray-700"
                        width="16"
                        height="13"
                        viewBox="0 0 16 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          d="M7.18079 9.25611C7.18079 10.0101 6.93759 10.6211 6.45119 11.0891C5.96479 11.5311 5.35039 11.7521 4.60799 11.7521C3.71199 11.7521 2.96958 11.4531 2.38078 10.8551C1.81758 10.2571 1.53598 9.39911 1.53598 8.28111C1.53598 7.08511 1.86878 5.91511 2.53438 4.77111C3.22559 3.60111 4.18559 2.67811 5.41439 2.00211L6.29759 3.36711C5.63199 3.83511 5.09439 4.35511 4.68479 4.92711C4.30079 5.49911 4.04479 6.16211 3.91679 6.91611C4.14719 6.81211 4.41599 6.76011 4.72319 6.76011C5.43999 6.76011 6.02879 6.99411 6.48959 7.46211C6.95039 7.93011 7.18079 8.52811 7.18079 9.25611ZM14.2464 9.25611C14.2464 10.0101 14.0032 10.6211 13.5168 11.0891C13.0304 11.5311 12.416 11.7521 11.6736 11.7521C10.7776 11.7521 10.0352 11.4531 9.44639 10.8551C8.88319 10.2571 8.60159 9.39911 8.60159 8.28111C8.60159 7.08511 8.93439 5.91511 9.59999 4.77111C10.2912 3.60111 11.2512 2.67811 12.48 2.00211L13.3632 3.36711C12.6976 3.83511 12.16 4.35511 11.7504 4.92711C11.3664 5.49911 11.1104 6.16211 10.9824 6.91611C11.2128 6.81211 11.4816 6.76011 11.7888 6.76011C12.5056 6.76011 13.0944 6.99411 13.5552 7.46211C14.016 7.93011 14.2464 8.52811 14.2464 9.25611Z"
                          fill="currentColor"
                        />
                      </svg>
                      <span className="relative z-10 italic text-gray-800 dark:text-gray-200">
                        Très content de l’isolation faite par 2AH. Travail
                        sérieux et soigné. Je les recommande !
                      </span>
                    </p>
                  </div>

                  <footer className="mt-6">
                    <div className="font-semibold text-blue-600">
                      <span className="material-symbols-outlined material-symbols-outlined-filled ">star</span>
                      <span className="material-symbols-outlined material-symbols-outlined-filled ">star</span>
                      <span className="material-symbols-outlined material-symbols-outlined-filled ">star</span>
                      <span className="material-symbols-outlined material-symbols-outlined-filled ">star</span>
                      <span className="material-symbols-outlined material-symbols-outlined-filled ">star</span>
                    </div>
                    <div className="font-semibold text-gray-800 dark:text-gray-200">
                      Sébastien Lefèvre
                    </div>
                    <div className="text-sm text-gray-500">
                      Projet Isolation Sarking | Issoire
                    </div>
                  </footer>
                </blockquote>
              </div>
              <div className="hs-carousel-slide  dark:bg-slate-900">
                <blockquote className="text-center lg:mx-auto lg:w-3/5">
                  <div className="mt-6 lg:mt-10">
                    <p className="relative text-xl sm:text-2xl md:text-3xl md:leading-normal font-medium text-gray-800">
                      <svg
                        className="absolute top-0 start-0 transform -translate-x-8 -translate-y-8 h-16 w-16 text-gray-200 sm:h-24 sm:w-24 dark:text-gray-700"
                        width="16"
                        height="13"
                        viewBox="0 0 16 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          d="M7.18079 9.25611C7.18079 10.0101 6.93759 10.6211 6.45119 11.0891C5.96479 11.5311 5.35039 11.7521 4.60799 11.7521C3.71199 11.7521 2.96958 11.4531 2.38078 10.8551C1.81758 10.2571 1.53598 9.39911 1.53598 8.28111C1.53598 7.08511 1.86878 5.91511 2.53438 4.77111C3.22559 3.60111 4.18559 2.67811 5.41439 2.00211L6.29759 3.36711C5.63199 3.83511 5.09439 4.35511 4.68479 4.92711C4.30079 5.49911 4.04479 6.16211 3.91679 6.91611C4.14719 6.81211 4.41599 6.76011 4.72319 6.76011C5.43999 6.76011 6.02879 6.99411 6.48959 7.46211C6.95039 7.93011 7.18079 8.52811 7.18079 9.25611ZM14.2464 9.25611C14.2464 10.0101 14.0032 10.6211 13.5168 11.0891C13.0304 11.5311 12.416 11.7521 11.6736 11.7521C10.7776 11.7521 10.0352 11.4531 9.44639 10.8551C8.88319 10.2571 8.60159 9.39911 8.60159 8.28111C8.60159 7.08511 8.93439 5.91511 9.59999 4.77111C10.2912 3.60111 11.2512 2.67811 12.48 2.00211L13.3632 3.36711C12.6976 3.83511 12.16 4.35511 11.7504 4.92711C11.3664 5.49911 11.1104 6.16211 10.9824 6.91611C11.2128 6.81211 11.4816 6.76011 11.7888 6.76011C12.5056 6.76011 13.0944 6.99411 13.5552 7.46211C14.016 7.93011 14.2464 8.52811 14.2464 9.25611Z"
                          fill="currentColor"
                        />
                      </svg>
                      <span className="relative z-10 italic text-gray-800 dark:text-gray-200">
                         Travaux de toiture réalisés avec soin. Charpente traitée avec expertise.
                      </span>
                    </p>
                  </div>

                  <footer className="mt-6">                    
                    <div className="font-semibold text-blue-600">
                      <span className="material-symbols-outlined material-symbols-outlined-filled ">star</span>
                      <span className="material-symbols-outlined material-symbols-outlined-filled ">star</span>
                      <span className="material-symbols-outlined material-symbols-outlined-filled ">star</span>
                      <span className="material-symbols-outlined material-symbols-outlined-filled ">star</span>
                      <span className="material-symbols-outlined material-symbols-outlined-filled ">star</span>
                    </div>
                    <div className="font-semibold text-gray-800 dark:text-gray-200">
                      Thierry Soreau
                    </div>
                    <div className="text-sm text-gray-500">
                      Projet Isolation Sarking + Traitement charpente | Laschamps
                    </div>
                  </footer>
                </blockquote>
              </div>
              <div className="hs-carousel-slide  dark:bg-slate-900">
                <blockquote className="text-center lg:mx-auto lg:w-3/5">
                  <div className="mt-6 lg:mt-10">
                    <p className="relative text-xl sm:text-2xl md:text-3xl md:leading-normal font-medium text-gray-800">
                      <svg
                        className="absolute top-0 start-0 transform -translate-x-8 -translate-y-8 h-16 w-16 text-gray-200 sm:h-24 sm:w-24 dark:text-gray-700"
                        width="16"
                        height="13"
                        viewBox="0 0 16 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          d="M7.18079 9.25611C7.18079 10.0101 6.93759 10.6211 6.45119 11.0891C5.96479 11.5311 5.35039 11.7521 4.60799 11.7521C3.71199 11.7521 2.96958 11.4531 2.38078 10.8551C1.81758 10.2571 1.53598 9.39911 1.53598 8.28111C1.53598 7.08511 1.86878 5.91511 2.53438 4.77111C3.22559 3.60111 4.18559 2.67811 5.41439 2.00211L6.29759 3.36711C5.63199 3.83511 5.09439 4.35511 4.68479 4.92711C4.30079 5.49911 4.04479 6.16211 3.91679 6.91611C4.14719 6.81211 4.41599 6.76011 4.72319 6.76011C5.43999 6.76011 6.02879 6.99411 6.48959 7.46211C6.95039 7.93011 7.18079 8.52811 7.18079 9.25611ZM14.2464 9.25611C14.2464 10.0101 14.0032 10.6211 13.5168 11.0891C13.0304 11.5311 12.416 11.7521 11.6736 11.7521C10.7776 11.7521 10.0352 11.4531 9.44639 10.8551C8.88319 10.2571 8.60159 9.39911 8.60159 8.28111C8.60159 7.08511 8.93439 5.91511 9.59999 4.77111C10.2912 3.60111 11.2512 2.67811 12.48 2.00211L13.3632 3.36711C12.6976 3.83511 12.16 4.35511 11.7504 4.92711C11.3664 5.49911 11.1104 6.16211 10.9824 6.91611C11.2128 6.81211 11.4816 6.76011 11.7888 6.76011C12.5056 6.76011 13.0944 6.99411 13.5552 7.46211C14.016 7.93011 14.2464 8.52811 14.2464 9.25611Z"
                          fill="currentColor"
                        />
                      </svg>
                      <span className="relative z-10 italic text-gray-800 dark:text-gray-200">
                        Changement de gouttière impeccable ! Entreprise sérieuse et efficace.
                      </span>
                    </p>
                  </div>

                  <footer className="mt-6">
                  <div className="font-semibold text-blue-600">
                      <span className="material-symbols-outlined material-symbols-outlined-filled ">star</span>
                      <span className="material-symbols-outlined material-symbols-outlined-filled ">star</span>
                      <span className="material-symbols-outlined material-symbols-outlined-filled ">star</span>
                      <span className="material-symbols-outlined material-symbols-outlined-filled ">star</span>
                      <span className="material-symbols-outlined material-symbols-outlined-filled text-yellow-400">award_star</span>
                    </div>
                    <div className="font-semibold text-gray-800 dark:text-gray-200">
                      Élodie Blanchard
                    </div>
                    <div className="text-sm text-gray-500">
                      Changement gouttière alu | Opme
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>

          <button
            type="button"
            className="hs-carousel-prev hs-carousel:disabled:opacity-50 disabled:pointer-events-none absolute inset-y-0 start-0 inline-flex justify-center items-center w-[46px] text-gray-800 hover:bg-gray-800/[.1]"
          >
            <span className="text-2xl" aria-hidden="true">
              <svg
                className="size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
              </svg>
            </span>
            <span className="sr-only">Previous</span>
          </button>
          <button
            type="button"
            className="hs-carousel-next hs-carousel:disabled:opacity-50 disabled:pointer-events-none absolute inset-y-0 end-0 inline-flex justify-center items-center w-[46px] text-gray-800 hover:bg-gray-800/[.1]"
          >
            <span className="sr-only">Next</span>
            <span className="text-2xl" aria-hidden="true">
              <svg
                className="size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
    </>
  )
}

export default Testimonials
