import { Link } from "react-router-dom";

const Home = () => {
  return <>
  
  <header>
        <div className="py-[20px] px-[50px]">
          <div className="flex flex-wrap gap-x-4">
            <Link to="/">Home</Link>
            <Link to="/MainPage">MainPage</Link>
            <Link to="/Features">Features</Link>
            <Link to="/Testimonials">Testimonials</Link>
            <Link to="/Hero">Hero</Link>
            <Link to="/Navbar">Navbar</Link>
          </div>
        </div>
      </header>


  </>;
};

export default Home;
