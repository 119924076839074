const Cards = () => {
    return <>


        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">

            <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
                <h2 className="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white">Nos Services</h2>
                <p className="mt-1 text-gray-600 dark:text-gray-400">Voici l'ensemble des services que nous proposons.</p>
            </div>

            <div className="flex gap-4 overflow-auto">
                {/* <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6"> */}



                <a href="Roofing" style={{ minWidth: "15em" }} className="w-2/4 flex flex-col bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]">
                    <div className="h-20 overflow-hidden flex flex-col justify-center items-center bg-rose-500 rounded-t-xl">

                        <img src="https://www.toitexpert.be/wp-content/uploads/2020/12/couverture-de-toiture.jpg" />
                    </div>
                    <div className="p-4 md:p-6">
                        <span className="block mb-1 text-xs font-semibold uppercase text-rose-600 dark:text-rose-500">
                            toiture, rénovation
                        </span>
                        <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:hover:text-white">
                            Couverture complète
                        </h3>
                        <p className="mt-3 text-gray-500">
                            Donnez un coup de neuf à votre toit avec notre service de toiture neuve. Nous vous proposons des matériaux de qualité et adaptés à votre budget.
                        </p>
                    </div>
                    <div className="mt-auto flex border-t border-gray-200 divide-x divide-gray-200 dark:border-gray-700 dark:divide-gray-700">
                        <a className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-xl bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="Roofing">
                            Decouvrir
                        </a>
                    </div>
                </a>



                <a href="Panne" style={{ minWidth: "15em" }} className="w-2/4 flex flex-col bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]">
                    <div className="h-20 overflow-hidden flex flex-col justify-center items-center bg-amber-500 rounded-t-xl">

                        <img src="https://medias.crea-concept.fr/images/news/news-listing-detail/comment-nettoyer-sa-toiture-1.jpg" />
                    </div>
                    <div className="p-4 md:p-6">
                        <span className="block mb-1 text-xs font-semibold uppercase text-amber-500">
                            nettoyage, longevité
                        </span>
                        <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:hover:text-white">
                            Nettoyage toiture / Résine
                        </h3>
                        <p className="mt-3 text-gray-500">Prolongez la durée de vie de votre toiture avec notre service de nettoyage toiture.</p>
                        <p className="mt-3 text-gray-500"> Stopper la porosité de votre toiture.
                        </p>
                    </div>
                    <div className="mt-auto flex border-t border-gray-200 divide-x divide-gray-200 dark:border-gray-700 dark:divide-gray-700">
                        <a className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-xl bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="Panne">
                            Decouvrir
                        </a>
                    </div>
                </a>


                <a  href="Isolation" style={{ minWidth: "15em" }} className="w-2/4 flex flex-col bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]">
                    <div className="h-20 overflow-hidden flex flex-col justify-center items-center bg-blue-600 rounded-t-xl">
                        <img src="https://vss.astrocenter.fr/habitatpresto/pictures/29997461-adobestock-409213655.jpeg" />
                    </div>
                    <div className="p-4 md:p-6">
                        <span className="block mb-1 text-xs font-semibold uppercase text-blue-600 dark:text-blue-500">
                            isolation, performance
                        </span>
                        <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:hover:text-white">
                            Isolation thermique
                        </h3>
                        <p className="mt-3 text-gray-500">Améliorez le confort et la performance énergétique de votre habitat avec notre service d’isolation thermique.
                        </p>
                        <p className="mt-3 text-gray-500">Eligible CEE.
                        </p>
                    </div>
                    <div className="mt-auto flex border-t border-gray-200 divide-x divide-gray-200 dark:border-gray-700 dark:divide-gray-700">
                        <a className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-xl bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="Isolation">
                            Decouvrir
                        </a>
                    </div>
                </a>


                <a href="UnderRoofing" style={{ minWidth: "15em" }} className="w-2/4 flex flex-col bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]">
                    <div className="h-20 overflow-hidden flex flex-col justify-center items-center bg-amber-500 rounded-t-xl">

                        <img src="https://www.ssb.fr/wp-content/uploads/sites/9491/2021/07/20190425_170024_resized-Copie-1024x535.jpg" />
                    </div>
                    <div className="p-4 md:p-6">
                        <span className="block mb-1 text-xs font-semibold uppercase text-green-500">
                            Protection, Planche de rive
                        </span>
                        <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:hover:text-white">
                            Habillage dessous de toit
                        </h3>
                        <p className="mt-3 text-gray-500">
                            Fini la lazure ou la peinture grace a l'habillage des dessous de toit. Cela va rajeunir et moderniser votre maison
                        </p>
                    </div>
                    <div className="mt-auto flex border-t border-gray-200 divide-x divide-gray-200 dark:border-gray-700 dark:divide-gray-700">
                        <a className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-xl bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="UnderRoofing">
                            Decouvrir
                        </a>
                        {/* <a className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-ee-xl bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">
                            Faire un devis
                        </a> */}
                    </div>
                </a>

                
                <a href="Gutter" style={{ minWidth: "15em" }} className="w-2/4 flex flex-col bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]">
                    <div className="h-20 overflow-hidden flex flex-col justify-center items-center bg-pink-600 rounded-t-xl">

                        <img src="https://www.travaux.com/images/cms/original/24985440-5dc3-465f-ac79-6ddd96bef570.jpg" />
                    </div>
                    <div className="p-4 md:p-6">
                        <span className="block mb-1 text-xs font-semibold uppercase text-pink-500">
                            Gouttière
                        </span>
                        <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:hover:text-white">
                            Pose de gouttière Aluminium
                        </h3>
                        <p className="mt-3 text-gray-500">
                        Optez pour notre expertise en installation de gouttières. Protégez votre maison des infiltrations d’eau avec des gouttières bien posées !          </p>
                    </div>
                    <div className="mt-auto flex border-t border-gray-200 divide-x divide-gray-200 dark:border-gray-700 dark:divide-gray-700">
                        <a className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-xl bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="Gutter">
                            Decouvrir
                        </a>
                        {/* <a className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-ee-xl bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">
                            Faire un devis
                        </a> */}
                    </div>
                </a>

                
                <a href="Bugs" style={{ minWidth: "15em" }} className="w-2/4 flex flex-col bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]">
                    <div className="h-20 overflow-hidden flex flex-col justify-center items-center bg-orange-600 rounded-t-xl">

                        <img src="https://th.bing.com/th/id/OIP.VlrGGTEfSlo5-hMRV2D_tgHaE8?w=284&h=189&c=7&r=0&o=5&dpr=2.5&pid=1.7" />
                    </div>
                    <div className="p-4 md:p-6">
                        <span className="block mb-1 text-xs font-semibold uppercase text-orange-500">
                            Charpente, Xylophage
                        </span>
                        <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:hover:text-white">
                            Traitement des bois curatifs
                        </h3>
                        <p className="mt-3 text-gray-500">Le traitement curatif consiste à pénétrer en profondeur dans le bois, renforçant ainsi sa résistance et sa longévité.    </p>
                    </div>
                    <div className="mt-auto flex border-t border-gray-200 divide-x divide-gray-200 dark:border-gray-700 dark:divide-gray-700">
                        <a className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-xl bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="Bugs">
                            Decouvrir
                        </a>
                        {/* <a className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-ee-xl bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">
                            Faire un devis
                        </a> */}
                    </div>
                </a>


            </div>

        </div>


    </>;
};

export default Cards;
