const Hero = () => {
    return <>
        <div className="relative overflow-hidden"
            style={{background:"url('/2ah_mark.svg')",
            backgroundRepeat: 'repeat',
            backgroundSize: '4em'
        }}  
            >        
            {/* <div className="relative overflow-hidden"
            style={{background:"url('/2ah_mark.svg')",
                    backgroundSize:"cover"
        }}  
            > */}
            <div aria-hidden="true" className="flex absolute -top-96 start-1/2 transform -translate-x-1/2">
                <div className="bg-gradient-to-r from-violet-300/50 to-purple-100 blur-3xl w-[25rem] h-[44rem] rotate-[-60deg] transform -translate-x-[10rem] dark:from-violet-900/50 dark:to-purple-900"></div>
                <div className="bg-gradient-to-tl from-blue-50 via-blue-100 to-blue-50 blur-3xl w-[90rem] h-[50rem] rounded-fulls origin-top-left -rotate-12 -translate-x-[15rem] dark:from-indigo-900/70 dark:via-indigo-900/70 dark:to-blue-900/70"></div>
            </div>

            <div className="relative z-10">
                <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-10 lg:py-16">
                    <div className="max-w-2xl text-center mx-auto">

                        <div className="mt-5 max-w-2xl">
                            <h1 className="block font-semibold text-gray-800 text-4xl md:text-5xl lg:text-6xl dark:text-gray-200">
                                L’expertise de la toiture et de l’isolation
                            </h1>
                        </div>


                        <div className="mt-5 max-w-3xl">
                            <p className="text-lg text-gray-600 dark:text-gray-400">Nous sommes une entreprise de couverture et d’isolation certifiée RGE et Qualibat, spécialisée dans la rénovation et l’amélioration de l’habitat en Auvergne.</p>
                        </div>


                        <div className="mt-8 gap-3 flex justify-center">
                            <a className="animate__headShake py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" 
                            href="/devis">
                                Demande de devis
                                <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
                            </a>
                            {/* <a className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">
                                <span className="material-symbols-outlined">
                                call
                                </span>
                                Etre rappelé
                            </a> */}
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </>;
};

export default Hero;
