import React from "react";
import { Link } from "react-router-dom";

const LocalSite = () => {
    return <>


        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">

            <div className="max-w-2xl mx-auto text-center">
                <h2 className="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white">Nous trouver</h2>
                <p className="mt-1 text-gray-600 dark:text-gray-400">Toutes les informations necessaires, pour un dialogue efficace.</p>
            </div>

            <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                <div className="grid md:grid-cols-2 gap-12 overflow-hidden">


                    <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7] overflow-hidden">

                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2784.293033567482!2d3.0951068999999993!3d45.745270999999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f71bcd99eeea57%3A0x6fa54bb5fedb2eea!2sAuvergne%20Am%C3%A9lioration%20de%20l&#39;Habitat%20-%20Couvreur%20Clermont-Ferrand!5e0!3m2!1sfr!2sfr!4v1734891890295!5m2!1sfr!2sfr"
                         width="600" height="250" 
                         style={{ border: "0" }}
                          allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        <div className="bg-gray-100 border-t rounded-b-xl py-3 px-4 md:py-4 md:px-5 dark:bg-slate-900 dark:border-gray-700">
                            <a target="_blank" className="mt-1 text-sm font-semibold cursor-pointer text-blue-500 dark:text-blue-500" href="https://maps.app.goo.gl/J2fWPnY6A1dkYMGd9">
                                Ouvrir dans maps
                            </a>
                        </div>
                    </div>


                    <div className="space-y-6 lg:space-y-10">
                        <div className="flex">
                            <div className="ms-5 sm:ms-8">
                                <h3 className="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                    Telephone
                                </h3>
                                <p className="mt-1 text-gray-600 dark:text-gray-400">

                                    <Link to="tel:0444449044">+33 4 44 44 90 44 </Link>
                                </p>
                            </div>
                        </div>

                        <div className="flex">
                            <div className="ms-5 sm:ms-8">
                                <h3 className="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                    Mail
                                </h3>
                                <p className="mt-1 text-gray-600 dark:text-gray-400">
                                    <Link to="mailto:test@gmail.com">contact@2-a-h.fr</Link>
                                </p>
                            </div>
                        </div>

                        <div className="flex">
                            <div className="ms-5 sm:ms-8">
                                <a href="https://maps.app.goo.gl/J2fWPnY6A1dkYMGd9">

                                    <h3 className="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                        Adresse
                                    </h3>
                                    <p className="mt-1 text-gray-600 dark:text-gray-400">
                                        6 rue Barbier d'aubrée
                                    </p>
                                    <p className="mt-1 text-gray-600 dark:text-gray-400">
                                        63100 Aubière
                                    </p>

                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default LocalSite;