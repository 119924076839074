import OtherArticles from "./OtherArticles";

export default function Roofing() {
    return (
        <>
            <div className="max-w-3xl px-4 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 mx-auto">
                <article className="prose prose-slate max-w-2xl">
                    <h2 className="text-2xl font-bold md:text-3xl dark:text-white"><span>Rénovation de toiture : une expertise technique au
                        service
                        de votre habitat</span></h2>
                    <h3 className="font-bold dark:text-white"><span>Introduction</span></h3>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span>La réfection d'une toiture constitue une opération
                        d'envergure, essentielle pour
                        préserver l'intégrité structurelle et l'étanchéité de votre habitation. Cette intervention complexe requiert
                        un savoir-faire éprouvé et une maîtrise parfaite des techniques de construction.</span></p>
                    <h3 className="font-bold dark:text-white"><span>Des étapes cruciales pour une rénovation réussie</span></h3>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span>Le processus de rénovation d'une toiture se décompose en
                        plusieurs phases distinctes,
                        chacune devant être exécutée avec rigueur et précision :</span></p>
                    <ol>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span className="font-bold">Diagnostic et expertise
                                préliminaires</span><span>:
                                    Une analyse approfondie de l'état de la charpente et de la couverture existante est indispensable afin
                                    de définir l'ampleur des travaux et de sélectionner les matériaux les plus adaptés.</span></p>
                        </li>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span className="font-bold">Désamiantage si nécessaire</span><span>: En cas de
                                présence d'amiante dans l'ancienne couverture, un désamiantage par des professionnels agréés est
                                obligatoire avant toute intervention.</span></p>
                        </li>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span className="font-bold">Préparation du chantier</span><span>: La mise en
                                sécurité du site, le bâchage des zones adjacentes et l'évacuation des déchets constituent des étapes
                                préalables essentielles.</span></p>
                        </li>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span className="font-bold">Mise en place du pare-pluie</span><span>: Cette
                                membrane étanche assure une protection optimale de la charpente contre les infiltrations d'eau. Sa pose
                                doit respecter les normes en vigueur et les spécificités du support.</span></p>
                        </li>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span className="font-bold">Pose des liteaux et sous-couches</span><span>: Les
                                liteaux servent de support à la couverture, tandis que les sous-couches apportent une protection
                                complémentaire contre l'humidité et les intempéries. Le choix des matériaux et leur mise en œuvre
                                doivent être effectués avec soin.</span></p>
                        </li>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span className="font-bold">Réalisation de la couverture</span><span>: Qu'il
                                s'agisse de tuiles, d'ardoises, de tôles ou d'autres matériaux, la pose de la couverture exige une
                                grande dextérité et un respect scrupuleux des techniques propres à chaque type de matériau.</span></p>
                        </li>
                        <li>
                            <p className="text-lg text-gray-800 dark:text-gray-200"><span className="font-bold">Travaux de finition</span><span>: La pose des
                                raccords, des abergements et des zingueries assure une étanchéité parfaite de la toiture et contribue à
                                son esthétique.</span></p>
                        </li>
                    </ol>
                    <h3 className="font-bold dark:text-white"><span>Littlage contre littlage : une technique de précision</span>
                    </h3>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span>Le littlage contre littlage est une méthode de pose de
                        tuiles qui requiert une
                        expertise aiguisée. Elle consiste à poser chaque tuile individuellement, en la recouvrant partiellement par
                        la tuile du rang supérieur. Cette technique rigoureuse garantit une couverture homogène et parfaitement
                        étanche.</span></p>
                    <h3 className="font-bold dark:text-white"><span>Confiez votre projet à des professionnels chevronnés</span>
                    </h3>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span>Compte tenu de sa complexité technique et des risques
                        potentiels, la rénovation d'une
                        toiture doit impérativement être confiée à des couvreurs professionnels expérimentés. Leur savoir-faire et
                        leur connaissance approfondie des normes de construction garantissent la qualité et la durabilité de
                        l'ouvrage.</span></p>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span></span></p>
                    <h3 className="font-bold dark:text-white"><span>2AH : votre partenaire pour une toiture irréprochable</span>
                    </h3>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span>Notre équipe de couvreurs qualifiés met à votre service
                        son expertise et son
                        savoir-faire pour tous vos besoins en matière de rénovation de toiture. Nous vous proposons un diagnostic
                        précis, des conseils personnalisés et un devis détaillé, ainsi qu'une réalisation irréprochable dans le
                        respect des normes les plus strictes.</span></p>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span>N'hésitez pas à nous contacter pour une prise en charge
                        experte de votre projet
                        de rénovation.</span></p>
                </article>
            </div>

            <OtherArticles />
        </>
    );
}