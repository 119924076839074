import { useEffect } from 'react';
import { useLocation, Routes, Route, Link } from 'react-router-dom';

import Home from './pages_old/Home';
import Features from './components/Features';
import Testimonials from './components/Testimonials';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import MainPage from './pages/MainPage';
import Devis from './pages/Devis';
import Contact from './pages/Contact';
import Isolation from './pages/articles/Isolation';
import Panne from './pages/articles/Panne';
import Footer from './components/Footer';
import Services from './pages/articles/Services';
import UnderRoofing from './pages/articles/UnderRoofing';
import Roofing from './pages/articles/Roofing';
import Gutter from './pages/articles/Gutter';
import Bugs from './pages/articles/Bugs';

function App() {
  const location = useLocation();

  useEffect(() => {
    require('preline/preline');
  }, []);

  useEffect(() => {
    // @ts-ignore
    HSStaticMethods.autoInit();
  }, [location.pathname]);

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/debug" element={<Home />} />
        <Route path="/" element={<MainPage />} />
        <Route path="/MainPage" element={<MainPage />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Devis" element={<Devis />} />
        <Route path="/Isolation" element={<Isolation />} />
        <Route path="/Panne" element={<Panne />} />
        <Route path="/Gutter" element={<Gutter />} />
        <Route path="/UnderRoofing" element={<UnderRoofing />} />
        <Route path="/Bugs" element={<Bugs />} />
        <Route path="/Roofing" element={<Roofing />} />
        <Route path="/Features" element={<Features />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Testimonials" element={<Testimonials />} />
        <Route path="/Hero" element={<Hero />} />
        <Route path="/Navbar" element={<Navbar />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
