import OtherArticles from "./OtherArticles";


export default function Gutter() {
    return (
        <>
            <div className="max-w-3xl px-4 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 mx-auto">
                <article className="prose prose-slate max-w-2xl">
                    <h2 className="text-2xl font-bold md:text-3xl dark:text-white"><span>Changer sa gouttière : un guide détaillé</span></h2>
                    <h3 className="font-bold dark:text-white"><span>Introduction</span></h3>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span >Les gouttières jouent un rôle crucial dans la protection de votre maison contre les
                        intempéries. En évacuant efficacement les eaux pluviales, elles empêchent l'accumulation d'eau sur le toit,
                        ce qui peut causer des dommages importants à la structure et à l'intérieur de votre maison.</span></p>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span >Si vos gouttières sont endommagées, fuyantes ou obstruées, il est important de les
                        remplacer rapidement.</span></p>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span>Changer ses gouttières soi-même</span><span>&nbsp;est un projet </span><span
                    >ambitieux</span><span>&nbsp;qui nécessite des </span><span>compétences en
                        bricolage</span><span>&nbsp;et des </span><span>outils spécifiques</span><span
                        >.</span></p>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span>Si vous n'êtes pas à l'aise avec les travaux en hauteur ou si vous n'avez pas les compétences
                        nécessaires, il est </span><span>recommandé de faire appel à un professionnel</span><span
                        >.</span></p>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span>Cependant, si vous souhaitez relever le défi, voici un guide détaillé pour vous aider
                        à changer vos gouttières étape par étape.</span></p>
                    <h4 className="font-bold dark:text-white"><span>Étape 1 : Préparation</span></h4>
                    <ul>
                        <li className="text-lg text-gray-800 dark:text-gray-200"><span>Assurez-vous que les conditions météorologiques sont
                            favorables</span><span >&nbsp;avant de commencer les travaux. Évitez les journées pluvieuses
                                ou venteuses.</span></li>
                        <li className="text-lg text-gray-800 dark:text-gray-200"><span>Réunissez tous les outils et matériaux nécessaires</span><span
                        >&nbsp;avant de commencer. Vous aurez besoin d'une échelle, d'une scie à métaux, d'une
                            perceuse, d'un marteau, d'un niveau à bulle, de crochets de gouttière, de gouttières, de descentes de
                            gouttière, de joints d'étanchéité et de scellant.</span></li>
                        <li className="text-lg text-gray-800 dark:text-gray-200"><span>Protégez la zone de travail</span><span >&nbsp;en posant
                            des bâches ou des cartons au sol sous les gouttières.</span></li>
                    </ul>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span ></span></p>
                    <h4 className="font-bold dark:text-white"><span>Étape 2 :</span><span>&nbsp;</span><span
                    >Démontage des gouttières existantes</span></h4>
                    <ul>
                        <li className="text-lg text-gray-800 dark:text-gray-200"><span>Commencez par démonter les descentes de gouttière</span><span
                        >. Retirez les fixations et détachez les descentes de la maison.</span></li>
                        <li className="text-lg text-gray-800 dark:text-gray-200"><span>Ensuite, retirez les gouttières</span><span >. Décrochez
                            les gouttières des crochets et faites-les glisser le long de la toiture.</span></li>
                        <li className="text-lg text-gray-800 dark:text-gray-200"><span>Retirez les crochets de gouttière</span><span >. Dévissez
                            les crochets de la façade de la maison.</span></li>
                    </ul>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span ></span></p>
                    <h4 className="font-bold dark:text-white"><span>Étape</span><span>&nbsp;3 : Installation des
                        nouvelles gouttières</span></h4>
                    <ul>
                        <li className="text-lg text-gray-800 dark:text-gray-200"><span>Installez les crochets de gouttière</span><span >. Fixez
                            les crochets à la façade de la maison en utilisant un niveau à bulle pour vous assurer qu'ils sont
                            alignés.</span></li>
                        <li className="text-lg text-gray-800 dark:text-gray-200"><span>Positionnez les gouttières</span><span >. Placez les
                            gouttières dans les crochets et assurez-vous qu'elles sont de niveau.</span></li>
                        <li className="text-lg text-gray-800 dark:text-gray-200"><span>Coupez les gouttières à la longueur souhaitée</span><span
                        >. Utilisez une scie à métaux pour couper les gouttières à la longueur appropriée en fonction
                            de la longueur de votre toit.</span></li>
                        <li className="text-lg text-gray-800 dark:text-gray-200"><span>Assemblez les gouttières</span><span >. Utilisez des
                            joints d'étanchéité et du scellant pour assembler les sections de gouttière.</span></li>
                        <li className="text-lg text-gray-800 dark:text-gray-200"><span>Installez les descentes de gouttière</span><span >. Fixez
                            les descentes de gouttière aux gouttières et à la maison en utilisant des colliers de serrage.</span>
                        </li>
                    </ul>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span ></span></p>
                    <h4 className="font-bold dark:text-white"><span>Étape </span><span>4</span><span
                    >&nbsp;: Vérification et finition</span></h4>
                    <ul >
                        <li className="text-lg text-gray-800 dark:text-gray-200"><span>Vérifiez que les gouttières sont de niveau</span><span
                        >&nbsp;et qu'elles sont correctement fixées aux crochets.</span></li>
                        <li className="text-lg text-gray-800 dark:text-gray-200"><span>Vérifiez l'étanchéité des joints</span><span >&nbsp;et des
                            raccords.</span></li>
                        <li className="text-lg text-gray-800 dark:text-gray-200"><span>Nettoyez les gouttières</span><span >&nbsp;pour éliminer
                            les débris et les feuilles.</span></li>
                    </ul>
                    <h4 className="font-bold dark:text-white"><span>Conseils</span><span>&nbsp;d'étanchéité</span>
                    </h4>
                    <ul>
                        <li className="text-lg text-gray-800 dark:text-gray-200"><span>Utilisez des joints d'étanchéité de qualité</span><span
                        >&nbsp;pour toutes les jonctions entre les sections de gouttière.</span></li>
                        <li className="text-lg text-gray-800 dark:text-gray-200"><span>Appliquez un scellant</span><span >&nbsp;sur tous les
                            joints et les raccords pour une étanchéité maximale.</span></li>
                        <li className="text-lg text-gray-800 dark:text-gray-200"><span>Vérifiez régulièrement l'état de vos gouttières</span><span
                        >&nbsp;et nettoyez-les si nécessaire pour éviter l'accumulation de débris qui pourraient
                            obstruer le flux d'eau.</span></li>
                    </ul>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span ></span></p>
                    <h3><span>Conclusion</span></h3>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span>Changer ses gouttières est un projet </span><span>important</span><span>&nbsp;qui
                    </span><span>contribue à protéger votre maison contre les dommages causés par les eaux
                        pluviales</span><span >.</span></p>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span ></span></p>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span>Si vous n'êtes pas à l'aise avec les travaux en hauteur ou si vous n'avez pas les compétences
                        nécessaires, il est </span><span>recommandé de faire appel à un professionnel</span><span
                        >.</span></p>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span ></span></p>
                    <p className="text-lg text-gray-800 dark:text-gray-200"><span>N'oubliez pas que votre sécurité est primordiale.</span><span
                    >&nbsp;Assurez-vous de prendre toutes les précautions nécessaires lorsque vous travaillez sur un
                        toit.</span></p>
                </article>
            </div>

            <OtherArticles />
        </>

    );
}