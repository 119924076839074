const Qualities = () => {
  return (
    <>
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 items-center gap-12">
          <div className="text-center">
            <div className="flex justify-center items-center size-12 bg-gray-50 border border-gray-200 rounded-full mx-auto dark:bg-gray-800 dark:border-gray-700">
              <span className="material-symbols-outlined  text-5xl text-blue-500">verified</span>
            </div>
            <div className="mt-3">
              <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                Qualité
              </h3>
              <p className="mt-1 text-gray-600 dark:text-gray-400">
                De nombreux clients satisfaits.
              </p>
            </div>
          </div>

          <div className="text-center">
            <div className="flex justify-center items-center size-12 bg-gray-50 border border-gray-200 rounded-full mx-auto dark:bg-gray-800 dark:border-gray-700">
              <span className="material-symbols-outlined  text-5xl text-blue-500">editor_choice</span>{' '}
            </div>
            <div className="mt-3">
              <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                Certifications
              </h3>
              <p className="mt-1 text-gray-600 dark:text-gray-400">
                RGE, Qualibat 
              </p>
            </div>
          </div>

          <div className="text-center">
            <div className="flex justify-center items-center size-12 bg-gray-50 border border-gray-200 rounded-full mx-auto dark:bg-gray-800 dark:border-gray-700">
              <span className="material-symbols-outlined  text-5xl text-blue-500">support_agent</span>{' '}
            </div>
            <div className="mt-3">
              <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                A votre écoute
              </h3>
              <p className="mt-1 text-gray-600 dark:text-gray-400">
                Savoir être a l'écoute de notre client est une force
              </p>
            </div>
          </div>

          <div className="text-center">
            <div className="flex justify-center items-center size-12 bg-gray-50 border border-gray-200 rounded-full mx-auto dark:bg-gray-800 dark:border-gray-700">
              <span className="material-symbols-outlined  text-5xl text-blue-500">roofing</span>{' '}
            </div>
            <div className="mt-3">
              <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                Garantie 10 ans
              </h3>
              <p className="mt-1 text-gray-600 dark:text-gray-400">
                Pour réaliser vos travaux en toute sérénité.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Qualities
