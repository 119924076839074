import AskDevisButton from "./AskDevisButton";
import Bugs from "./Bugs";
import Gutter from "./Gutter";
import Isolation from "./Isolation";
import Panne from "./Panne";
import UnderRoofing from "./UnderRoofing";

export default function Services() {
    return (
        <>
            <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                <a className="mb-4 inline-flex items-center gap-x-1 text-sm text-gray-800 hover:text-blue-600 dark:text-gray-200 dark:hover:text-blue-500" href="#">
                    <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m15 18-6-6 6-6" /></svg>
                    Retour à l'accueil
                </a>

                <nav className="max-w-6xl mx-auto grid sm:flex gap-y-px sm:gap-y-0 sm:gap-x-4" aria-label="Tabs" role="tablist">
                    <button type="button" className="hs-tab-active:bg-gray-100 hs-tab-active:hover:border-transparent w-full flex flex-col text-start hover:bg-gray-100 p-3 md:p-5 rounded-xl dark:hs-tab-active:bg-white/[.05] dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 active" id="tabs-with-card-item-1" data-hs-tab="#tabs-with-card-1" aria-controls="tabs-with-card-1" role="tab">
                        <span >
                            <span className="hs-tab-active:text-blue-600 block font-semibold text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-gray-200">Isolation des combles</span>
                            <span className="hidden lg:block mt-2 text-gray-800 dark:text-gray-200">Guide exhaustif pour une rénovation performante</span>
                        </span>
                    </button>

                    <button type="button" className="hs-tab-active:bg-gray-100 hs-tab-active:hover:border-transparent w-full flex flex-col text-start hover:bg-gray-100 p-3 md:p-5 rounded-xl dark:hs-tab-active:bg-white/[.05] dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" id="tabs-with-card-item-2" data-hs-tab="#tabs-with-card-2" aria-controls="tabs-with-card-2" role="tab">
                        <span >
                            <span className="hs-tab-active:text-blue-600 block font-semibold text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-gray-200">Dessous de toit en PVC</span>
                            <span className="hidden lg:block mt-2 text-gray-800 dark:text-gray-200">Un guide détaillé pour fixer les dessous de toit en PVC</span>
                        </span>
                    </button>

                    <button type="button" className="hs-tab-active:bg-gray-100 hs-tab-active:hover:border-transparent w-full flex flex-col text-start hover:bg-gray-100 p-3 md:p-5 rounded-xl dark:hs-tab-active:bg-white/[.05] dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" id="tabs-with-card-item-3" data-hs-tab="#tabs-with-card-3" aria-controls="tabs-with-card-3" role="tab">
                        <span >
                            <span className="hs-tab-active:text-blue-600 block font-semibold text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-gray-200">Nettoyage et entretien de votre couverture</span>
                            <span className="hidden lg:block mt-2 text-gray-800 dark:text-gray-200">guide complet et conseils pour une intervention professionnelle</span>
                        </span>
                    </button>

                    <button type="button" className="hs-tab-active:bg-gray-100 hs-tab-active:hover:border-transparent w-full flex flex-col text-start hover:bg-gray-100 p-3 md:p-5 rounded-xl dark:hs-tab-active:bg-white/[.05] dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" id="tabs-with-card-item-4" data-hs-tab="#tabs-with-card-4" aria-controls="tabs-with-card-4" role="tab">
                        <span >
                            <span className="hs-tab-active:text-blue-600 block font-semibold text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-gray-200">Pose de gouttière aluminium</span>
                            <span className="hidden lg:block mt-2 text-gray-800 dark:text-gray-200">guide complet et conseils pour une intervention professionnelle</span>
                        </span>
                    </button>

                    <button type="button" className="hs-tab-active:bg-gray-100 hs-tab-active:hover:border-transparent w-full flex flex-col text-start hover:bg-gray-100 p-3 md:p-5 rounded-xl dark:hs-tab-active:bg-white/[.05] dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" id="tabs-with-card-item-5" data-hs-tab="#tabs-with-card-5" aria-controls="tabs-with-card-5" role="tab">
                        <span >
                            <span className="hs-tab-active:text-blue-600 block font-semibold text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-gray-200">Traitement de charpente</span>
                            <span className="hidden lg:block mt-2 text-gray-800 dark:text-gray-200">guide complet et conseils pour un traitement de charpente</span>
                        </span>
                    </button>
                </nav>

                <div className="mt-12 md:mt-16">
                    <div id="tabs-with-card-1" role="tabpanel" aria-labelledby="tabs-with-card-item-1">
                        <Isolation />
                        <AskDevisButton />
                    </div>

                    <div id="tabs-with-card-2" className="hidden" role="tabpanel" aria-labelledby="tabs-with-card-item-2">

                        <UnderRoofing />
                        <AskDevisButton />
                    </div>

                    <div id="tabs-with-card-3" className="hidden" role="tabpanel" aria-labelledby="tabs-with-card-item-3">
                        <Panne />
                        <AskDevisButton />
                    </div>

                    <div id="tabs-with-card-4" className="hidden" role="tabpanel" aria-labelledby="tabs-with-card-item-4">
                        <Gutter />
                        <AskDevisButton />
                    </div>

                    <div id="tabs-with-card-5" className="hidden" role="tabpanel" aria-labelledby="tabs-with-card-item-5">
                        <Bugs />
                        <AskDevisButton />
                    </div>
                </div>
            </div>
        </>
    );
}