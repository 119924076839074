import { useState } from "react";
import emailjs from "@emailjs/browser";
import { ChangeEvent, FormEvent } from "react";

// Initialisez EmailJS avec votre clé publique
emailjs.init("Ntj9oaUeory3nGrCK");

export default function Contact() {
    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        details: ""
    });

    const [status, setStatus] = useState({
        submitted: false,
        error: false,
        message: ""
    });

    const [loading, setLoading] = useState(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setStatus({ submitted: false, error: false, message: "" });
        setLoading(true);

        try {
            const result = await emailjs.send(
                "service_o9oiyaw", // exemple: service_xyz123
                "template_50xaap2", // exemple: template_abc456
                {
                    from_name: `${formData.firstname} ${formData.lastname}`,
                    from_email: formData.email,
                    phone: formData.phone,
                    message: formData.details,
                    to_name: "2AH", // Nom de votre entreprise
                    // Ajoutez d'autres variables utilisées dans votre template
                }
            );

            if (result.status === 200) {
                // add some logs
                setStatus({
                    submitted: true,
                    error: false,
                    message: "Votre message a été envoyé avec succès !"
                });
                // Réinitialiser le formulaire
                setFormData({
                    firstname: "",
                    lastname: "",
                    email: "",
                    phone: "",
                    details: ""
                });
                window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top with animation
            }
        } catch (error) {
            setStatus({
                submitted: false,
                error: true,
                message: "Une erreur est survenue lors de l'envoi du formulaire."
            });
            console.error("Erreur EmailJS:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                <div className="max-w-xl mx-auto">
                    <div className="text-center flex gap-4 flex-col">
                        <h1 className="text-3xl font-bold text-gray-800 sm:text-4xl dark:text-white">
                            Nous contacter
                        </h1>
                        <p className="mt-1 text-gray-600 dark:text-gray-400">
                            Vous souhaitez donner votre avis, ou nous laisser un mail, remplissez ce formulaire.
                        </p>

                        <div className="flex justify-center">
                            <a className="inline-flex items-center gap-x-2 bg-white border border-gray-200 text-xs text-gray-600 p-2 px-3 rounded-full transition hover:border-gray-300 dark:bg-gray-800 dark:border-gray-700 dark:hover:border-gray-600 dark:text-gray-400" href="/Devis">
                                Pour toute demande de devis
                                <span className="flex items-center gap-x-1">
                                    <span className="border-s border-gray-200 text-blue-600 ps-2 dark:text-blue-500">Cliquez ici</span>
                                    <svg className="flex-shrink-0 size-4 text-blue-600" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
                                </span>
                            </a>
                        </div>

                    </div>
                </div>

                <div className="mt-12 max-w-lg mx-auto">
                    <div className="flex flex-col border rounded-xl p-4 sm:p-6 lg:p-8 dark:border-gray-700">
                        {status.message && (
                            <div className={`p-4 mb-4 rounded-lg ${status.error ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'}`}>
                                {status.message}
                            </div>
                        )}

                        <form onSubmit={handleSubmit}>
                            <div className="grid gap-4 lg:gap-6">
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
                                    <div>
                                        <label htmlFor="firstname" className="block mb-2 text-sm text-gray-700 font-medium dark:text-white">Prenom</label>
                                        <input type="text" name="firstname" id="firstname" value={formData.firstname} onChange={handleChange} className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" />
                                    </div>

                                    <div>
                                        <label htmlFor="lastname" className="block mb-2 text-sm text-gray-700 font-medium dark:text-white">Nom</label>
                                        <input type="text" name="lastname" id="lastname" value={formData.lastname} onChange={handleChange} className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" />
                                    </div>
                                </div>

                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
                                    <div>
                                        <label htmlFor="email" className="block mb-2 text-sm text-gray-700 font-medium dark:text-white">Email</label>
                                        <input type="email" name="email" id="email" value={formData.email} onChange={handleChange} autoComplete="email" className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" />
                                    </div>

                                    <div>
                                        <label htmlFor="phone" className="block mb-2 text-sm text-gray-700 font-medium dark:text-white">Numéro de téléphone</label>
                                        <input type="text" name="phone" id="phone" value={formData.phone} onChange={handleChange} className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" />
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="details" className="block mb-2 text-sm text-gray-700 font-medium dark:text-white">Details</label>
                                    <textarea id="details" name="details" value={formData.details} onChange={handleChange} rows={4} className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"></textarea>
                                </div>
                            </div>

                            <div className="mt-6 grid">
                                <button type="submit" className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" disabled={loading}>
                                    {loading ? "Envoi en cours..." : "Envoyer"}
                                </button>
                            </div>

                            <div className="mt-3 text-center">
                                <p className="text-sm text-gray-500">
                                    Nous reviendrons vers vous d'ici 1 a 2 jours ouvrés.
                                </p>
                            </div>
                        </form>
                    </div>
                </div>


            </div>
        </>
    );
}