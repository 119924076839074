import { useState } from "react";
import emailjs from "@emailjs/browser";
import { ChangeEvent, FormEvent } from "react";

// Initialisez EmailJS avec votre clé publique
emailjs.init("Ntj9oaUeory3nGrCK");

export default function Devis() {
    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        additionalInfo: "",
        type:""
    });

    const [status, setStatus] = useState({
        submitted: false,
        error: false,
        message: ""
    });

    const [loading, setLoading] = useState(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleTypeChange = (type: string) => {
        setFormData({
            ...formData,
            type: type
        });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setStatus({ submitted: false, error: false, message: "" });
        setLoading(true);

        try {
            const result = await emailjs.send(
                "service_o9oiyaw", // exemple: service_xyz123
                "template_50t5baf", // exemple: template_abc456
                {
                    from_name: `${formData.firstname} ${formData.lastname}`,
                    from_email: formData.email,
                    phone: formData.phone,
                    message: formData.additionalInfo,
                    type:formData.type,
                    to_name: "2AH", // Nom de votre entreprise
                    // Ajoutez d'autres variables utilisées dans votre template
                }
            );

            if (result.status === 200) {
                // add some logs
                setStatus({
                    submitted: true,
                    error: false,
                    message: "Votre demande de devis a été envoyée avec succès !"
                });
                // Réinitialiser le formulaire
                setFormData({
                    firstname: "",
                    lastname: "",
                    email: "",
                    phone: "",
                    additionalInfo: "",
                    type:""
                });
                window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top with animation
            }
        } catch (error) {
            setStatus({
                submitted: false,
                error: true,
                message: "Une erreur est survenue lors de l'envoi du formulaire."
            });
            console.error("Erreur EmailJS:", error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                <div className="max-w-2xl mx-auto">

                {status.message && (
                        <div className={`p-4 mb-4 rounded-lg ${status.error ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'}`}>
                            {status.message}
                        </div>
                    )}

                    <form onSubmit={handleSubmit}>
                        <div className="grid gap-12">
                            <div>
                                <h2 className="text-3xl text-gray-800 font-bold lg:text-4xl dark:text-white">
                                    Demande de devis
                                </h2>
                                <p className="mt-3 text-gray-800 dark:text-gray-400">
                                    Depuis toujours, la toiture est un élément essentiel de l’habitat, qui assure confort, protection et esthétique. <span className="font-bold">2AH</span> vous propose de profiter de son savoir-faire et de son expérience pour réaliser tous vos projets de toiture, qu’il s’agisse d’isolation, de couverture, de nez de panne ou de sarking.  </p>
                            </div>

                            <div className="hs-dropdown relative inline-flex">
                                <button id="hs-dropdown-default" type="button" className="hs-dropdown-toggle py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                                    {formData.type || "Choisir un type de prestation"}
                                    <svg className="hs-dropdown-open:rotate-180 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6" /></svg>
                                </button>

                                <div className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg p-2 mt-2 dark:bg-gray-800 dark:border dark:border-gray-700 dark:divide-gray-700 after:h-4 after:absolute after:-bottom-4 after:start-0 after:w-full before:h-4 before:absolute before:-top-4 before:start-0 before:w-full" aria-labelledby="hs-dropdown-default">
                                    <a className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700" href="#" onClick={() => handleTypeChange('Couverture')} >
                                        Couverture
                                    </a>                         
                                    <a className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700" href="#" onClick={() => handleTypeChange('Isolation')}>
                                        Isolation
                                    </a>
                                    <a className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700" href="#" onClick={() => handleTypeChange('Toiture')}>
                                        Toiture
                                    </a>
                                    <a className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700" href="#" onClick={() => handleTypeChange('Nez de panne')}>
                                        Nez de panne <span className="inline-flex items-center gap-x-1.5 py-0.5 px-2 rounded-full text-xs font-bold border border-red-500 text-red-500">-10%</span>
                                    </a>
                                    <a className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700" href="#" onClick={() => handleTypeChange('Traitement de charpente')}>
                                        Traitement de charpente
                                    </a>
                                    <a className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700" href="#" onClick={() => handleTypeChange('Sarking')}>
                                        Sarking
                                    </a>
                                    <a className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700" href="#" onClick={() => handleTypeChange('Autre')}>
                                        Autre
                                    </a>
                                </div>
                            </div>

                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
                                <div>
                                    <label htmlFor="hs-firstname-contacts-1" className="block mb-2 text-sm text-gray-700 font-medium dark:text-white">Prenom</label>
                                    <input type="text" name="firstname" id="hs-firstname-contacts-1" value={formData.firstname} onChange={handleChange} className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" />
                                </div>

                                <div>
                                    <label htmlFor="hs-lastname-contacts-1" className="block mb-2 text-sm text-gray-700 font-medium dark:text-white">Nom</label>
                                    <input type="text" name="lastname" id="hs-lastname-contacts-1" value={formData.lastname} onChange={handleChange} className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" />
                                </div>
                            </div>

                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
                                <div>
                                    <label htmlFor="hs-email-contacts-1" className="block mb-2 text-sm text-gray-700 font-medium dark:text-white">Email</label>
                                    <input type="email" name="email" id="hs-email-contacts-1" value={formData.email} onChange={handleChange} autoComplete="email" className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" />
                                </div>

                                <div>
                                    <div className="flex justify-between">
                                        <label htmlFor="hs-phone-number-1" className="block mb-2 text-sm text-gray-700 font-medium dark:text-white">Numéro de téléphone</label>
                                        <div className="col-start-2 text-center">
                                            <div className="hs-tooltip inline-block">
                                                <button type="button" className="hs-tooltip-toggle size-10 inline-flex justify-center items-center gap-2 rounded-full bg-white/[.1] border border-white/[.3] text-blue-600 ">
                                                    <span className="material-symbols-outlined">
                                                        info
                                                    </span>
                                                    <span className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-slate-700" role="tooltip">
                                                        Nous permet de vous rappeler plus rapidement 
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="text" name="phone" id="hs-phone-number-1" value={formData.phone} onChange={handleChange} className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="hs-about-contacts-1" className="block mb-2 text-sm text-gray-700 font-medium dark:text-white">Informations complémentaires</label>
                                <textarea id="hs-about-contacts-1" name="additionalInfo" value={formData.additionalInfo} onChange={handleChange} rows={4} className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"></textarea>
                            </div>

                            <div className="mt-6 grid">
                                <button type="submit" className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" disabled={loading}>
                                    {loading ? "Envoi en cours..." : "Envoyer ma demande de devis"}
                                </button>
                            </div>

                            <div className=" text-center">
                                <p className="text-sm text-gray-500">
                                    Nous reviendrons vers vous d'ici 1 a 2 jours ouvrés.
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}