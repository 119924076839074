const Sponsors = () => {
  return (
    <>
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="sm:w-1/2 xl:w-1/3 mx-auto text-center mb-6 md:mb-12">
          <h2 className="text-xl font-semibold md:text-2xl md:leading-tight text-gray-800 dark:text-gray-200">Nous travaillons avec tout ces organismes</h2>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 gap-3 lg:gap-6">
          <div className="p-4 md:p-7 flex bg-gray-100 rounded-lg dark:bg-gray-800">
            <object className="py-3 lg:py-5 w-16 h-auto m-auto md:w-20 lg:w-24 mx-auto" type="image/svg+xml" data="images/cee.svg"></object>
          </div>

          <div className=" bg-gray-100 rounded-lg dark:bg-gray-800">
            <img className="py-3 lg:py-5 w-16 h-auto m-auto md:w-20 lg:w-24 mx-auto" src="https://images.prismic.io/travauxlib%2F3fa3e9bb-6c86-4182-9f5f-6521078cd3bd_logo_qualibat-rge+hd.png?auto=compress,format&rect=0,0,2400,2911&w=2400&h=2911" />
          </div>

          <div className="p-4 md:p-7 flex bg-gray-100 rounded-lg dark:bg-gray-800">
            <img className="py-3 lg:py-5 w-16 h-auto m-auto md:w-20 lg:w-24 mx-auto" src="https://upload.wikimedia.org/wikipedia/fr/b/b4/CSTB-logo-2015-RVB-HD.jpg" />
          </div>

          <div className="p-4 md:p-7 flex bg-gray-100 rounded-lg dark:bg-gray-800">
            <svg  className="py-3 lg:py-5 w-16 h-auto m-auto md:w-20 lg:w-24 mx-auto" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160 75.7">
              <title>logo CAPEB</title>
              <g id="Calque_1">
                <g className="text">
                  <path className="st0" d="M74.8,50c0.1-0.1,0.3-0.2,0.3-0.3c0.4-0.4,0.5-0.8,1-1l0.3-0.1l0.1,0.1l0.2,0.2c-0.2,0.3-0.5,0.6-0.7,0.8
        			c-0.1,0.1-0.3,0.1-0.4,0.2L75.4,50c-0.1,0.1-0.4,0.2-0.6,0.3C74.8,50.2,74.7,50.1,74.8,50"></path>
                  <path className="st0" d="M139.5,55l-0.2-0.2c-0.2-0.2-0.3-0.4-0.3-0.7c-0.4,0.3-1.1,0.9-1.7,0.9h-0.1l0,0c-0.4-0.2-0.3-0.8-0.2-1.1
        			l0,0h-0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.4,0.3-0.5,0.4c-0.1,0.1-0.3,0.3-0.5,0.4h-0.1l-0.2-0.1c-0.1-0.3,0.1-0.8,0.1-1
        			h-0.1c-0.5,0.3-1,0.6-1.6,0.8c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0.1-0.4,0.1-0.6,0.2l0,0c-0.1,0-0.3,0-0.4,0
        			c-0.2-0.1-0.3-0.5-0.4-0.4l-0.3,0.2c-0.3,0.1-0.7,0.3-1,0.1c-0.2-0.1-0.3-0.4-0.3-0.6c0-0.1,0.1-0.2,0-0.3l-0.1-0.2
        			c-0.4,0.4-0.8,0.8-1.3,1c-0.1,0-0.2,0.1-0.3,0.1l-0.1-0.2c-0.1-0.4,0-0.6,0.1-0.9c0-0.1,0-0.3-0.1-0.2s-0.2,0.2-0.2,0.3
        			c-0.1,0.1-0.2,0.2-0.3,0.3l-0.2,0.2l-0.2,0.1l-0.2,0.1l0,0L127,55c-0.5,0.2-0.3-0.5-0.3-0.7c0.1-0.3,0.3-1.6,0-1.7
        			c-0.2,0-0.4,0.2-0.5,0.2c-0.2,0.1-0.3,0.2-0.5,0.3l-0.1,0.1l0,0v0.2l-0.5,0.6h-0.2c-0.1,0-0.2,0-0.2-0.1v-0.2c0-0.1-0.1-0.2,0-0.3
        			c0.1-0.2,0.5-0.6,0.7-0.8l0.1-0.1c0.3-0.2,1.2-0.8,1.6-0.8c0.2,0,0.3,0.1,0.4,0.1c0.2,0.1,0.3,0.2,0.4,0.4v0.1l-0.1,0.7
        			c0.4-0.3,0.8-0.7,1.2-1c0.1-0.1,0.3-0.2,0.4,0c0.1,0.1,0.2,0.2,0.2,0.3c0,0.2-0.1,0.3-0.1,0.5c-0.1,0.2-0.2,0.5-0.2,0.7h0.1
        			l0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0.4-0.4,0.5-0.5c0.2-0.1,0.7-0.6,1-0.2c0.3,0.4-0.5,1.4,0.1,1.6c0.2,0.1,0.3,0,0.4-0.1
        			c0.2-0.1,0.2-0.5,0.3-0.7c0.1-0.3,0.3-0.7,0.5-0.9c0.3-0.3,1.1-1,1.7-0.8c0.2,0,0.3,0.2,0.4,0.4c0.6,0.8-0.6,1.4-1,1.7
        			c-0.2,0.1-0.4,0.3-0.6,0.4c-0.1,0.1-0.1,0.1-0.1,0.2l0.1,0.1c0.2,0.1,0.5-0.1,0.7-0.1c0.6-0.2,1.1-0.6,1.5-1
        			c0.2-0.2,0.4-0.4,0.6-0.6c0.1-0.1,0.3-0.3,0.5-0.2c0.1,0,0.2,0.1,0.3,0.2h0.1c0.1,0.2,0,0.5-0.1,0.6s-0.1,0.3-0.1,0.5
        			c0.2-0.1,0.4-0.3,0.6-0.4c0.1-0.1,0.3-0.3,0.5-0.4c0.2-0.1,0.4,0,0.6,0c0.1,0.3,0,1.2,0.2,1.3l0,0c0.7,0.1,1.3-1.7,1.5-2.2
        			c0.1-0.3,0.3-0.7,0.5-1c0.1-0.2,0.3-0.4,0.6-0.5c0.1,0,0.2-0.1,0.4-0.1s0.2,0.2,0.3,0.3c-0.1,0.2-0.3,0.4-0.5,0.6
        			c-0.1,0.2-0.3,0.5-0.4,0.8c0.2-0.1,0.3-0.2,0.5-0.2c0.7-0.2,1.4-0.2,2.1-0.3c0.2,0,0.6-0.1,0.8,0l0.1,0.1
        			c-0.1,0.4-0.5,0.4-0.8,0.5c-0.8,0.2-1.5,0.4-2.3,0.5c-0.2,0-0.6,0-0.7,0.1c-0.1,0.1-0.2,0.8-0.2,0.9c0,0.3,0.1,0.6,0.3,0.7
        			c0.5,0.5,0.8,0.3,1.4,0c0.1-0.1,0.4-0.2,0.6-0.1c0,0.1,0,0.3-0.1,0.3c-0.1,0.1-0.7,0.4-0.9,0.5h-0.1c-0.4,0.2-0.9,0.4-1.3,0.4
        			C139.8,55.1,139.7,55.1,139.5,55 M132.9,53v0.3c0.2-0.1,1.2-0.8,0.8-1h-0.3C133.3,52.2,132.9,52.8,132.9,53"></path>
                  <path className="st0" d="M115.9,53.5c0.1-0.1,0.2-0.2,0.2-0.3c0.5-0.6,1.1-1.3,1.8-1.7c0.3-0.2,0.7-0.2,1-0.1c0.1,0.1,0.2,0.2,0.3,0.2
        			c0.1,0,0.2,0,0.3,0v0.1c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.1,0.3-0.2,0.4c-0.2,0.4-0.4,0.8-0.4,1.4c0,0.4,0.2,0.1,0.4,0
        			c0.4-0.3,0.8-0.6,1.1-1c0.3-0.4,0.7-0.8,0.8-1.3c-0.3-0.1-0.3-0.3-0.2-0.6c0.1-0.2,0.3-0.2,0.4-0.4c0.2,0,0.3,0.1,0.4-0.1
        			c0.3-0.3,0.5-0.7,0.8-1l0.2-0.1l0,0h0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0.1c-0.2,0.4-0.5,0.7-0.8,1.1c0.1,0,0.2,0,0.3,0.1
        			c0.2,0.1,0.5,0,0.7,0c0.4,0,1.3,0.1,1.4,0.2c0.1,0.1-0.1,0.1-0.1,0.2l-0.1,0.2c-0.1,0.2-0.4,0.2-0.7,0.3c-0.4,0.1-0.9,0.1-1.3,0.1
        			h-0.1c-0.2,0-0.6-0.1-0.8,0l-0.3,0.4c-0.2,0.4-0.2,0.8-0.3,1.2c-0.2,1.1,1.5-0.4,1.7-0.5s0.3-0.3,0.4-0.5l0.1-0.2l0.2-0.1l0.2-0.2
        			c0.2-0.1,0.5,0.1,0.5,0.2l0,0l-0.1,0.1c-0.3,0.4-0.6,0.9-0.5,1.6c0,0.1,0.2,0.6,0.2,0.6l-0.2,0.1l-0.2,0.1
        			c-0.1,0.1-0.3,0.2-0.4,0.3c-0.3,0.1-0.5-0.3-0.5-0.6s0-0.4,0-0.6c-0.2,0-0.3,0.1-0.4,0.2c-0.4,0.2-1.3,0.9-1.8,0.6l-0.2-0.1
        			c-0.1-0.2-0.1-0.4-0.1-0.7l0,0c-0.4,0.3-1.5,1.3-2.1,1.3c-0.3,0-0.4-0.5-0.4-0.8v-0.1v-0.2h-0.1l-0.3,0.3
        			c-0.2,0.2-0.8,0.7-1.1,0.8h-0.1C115.3,55.2,115.8,53.8,115.9,53.5 M117.1,53c-0.2,0.2-0.3,0.5-0.4,0.7c0,0.1-0.1,0.2-0.1,0.3
        			c0.5-0.1,0.7-0.6,1-0.9l0.2-0.2c0.1-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.2,0.2-0.4H118C117.6,52.4,117.4,52.6,117.1,53"></path>
                  <path className="st0" d="M97.1,54.8c0-0.3-0.1-0.6,0-1c0.2-0.6,0.8-1.2,1.2-1.6c0.6-0.5,1.1-1,2.1-0.7c0.4-0.6,0.9-1.1,1.4-1.7
        			c0.1-0.2,0.4-0.6,0.6-0.7l0.6,0.1c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0.2-0.3,0.3-0.5,0.5c-0.1,0.1-0.1,0.3-0.2,0.4l-0.1,0.1
        			c-0.2,0.2-0.2,0.4-0.4,0.6c-0.1,0.2-0.4,0.4-0.5,0.6c-0.2,0.2-0.2,0.5-0.4,0.8c-0.1,0.3-0.3,0.6-0.2,1l0.1,0.2
        			c0.2,0.2,1.3-0.8,1.4-1c0.2-0.2,0.3-0.4,0.5-0.7s0.7-0.9,1.1-0.7l0.2,0.1l0,0c0,0.2-0.1,0.4-0.2,0.5l-0.1,0.1
        			c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.2-0.2,0.5-0.3,0.8v0.1c0,0.2,0.1,0.1,0.2,0.1c0.6-0.3,0.9-0.9,1.3-1.4l0.2-0.1h0.2
        			c0.1,0,0.3,0.2,0.3,0.3s-0.2,0.3-0.2,0.4c-0.1,0.3-0.1,0.8,0.1,1c0.1,0.1,0.2,0.2,0.4,0.2c0.2,0,0.5-0.2,0.7-0.3s0.2,0,0.3,0.1
        			c-0.1,0.4-1,1-1.4,1.1c-0.7,0.2-1-0.5-1.1-0.9c-0.4,0.2-1,1.1-1.5,0.8c-0.4-0.2-0.3-0.7-0.2-1.1c-0.2,0-0.6,0.4-0.8,0.6
        			c-0.2,0.2-0.5,0.3-0.8,0.5c-0.2,0.1-0.3,0.1-0.5,0c-0.5-0.2-0.1-1.1-0.4-1.2c-0.5,0.6-1.1,1.3-1.9,1.5c-0.1,0-0.2,0.1-0.3,0.1
        			C97.3,55.1,97.2,55,97.1,54.8 M99.5,52.3c-0.6,0-2,1.5-1.3,1.8c0.5-0.5,1.1-0.9,1.6-1.4C99.8,52.5,99.8,52.3,99.5,52.3L99.5,52.3"></path>
                  <path className="st0" d="M119.5,50.5c0-0.3-0.1-0.5-0.4-0.5c-0.6,0-1,0.2-1.4,0.4c-0.1,0-0.2,0.1-0.3,0c-0.1-0.3,0.3-0.6,0.5-0.7
        			c0.4-0.3,0.9-0.4,1.5-0.5c0.4,0,1.2,0.2,1.3,0.6c0,0.3-0.2,0.3-0.4,0.3v0.2l-0.5,0.4c0,0,0,0-0.1,0
        			C119.6,50.6,119.5,50.5,119.5,50.5"></path>
                  <path className="st0" d="M67.1,54.9c-0.2-0.8,0.2-1.4,0.6-2c0.1-0.2,0.2-0.2,0.3-0.5c-0.1,0-1-0.3-1.1-0.3l-0.1,0.1
        			c-0.1,0.2-0.1,0.4-0.3,0.5c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0.3-0.2,0.6-0.7,0.5v-0.2c0.1-0.1-0.1-0.2,0-0.4
        			c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.3,0.4-1.2,0.6-1.3c0.4-0.2,1.1,0.1,1.6,0.2c0.3,0,0.7,0.1,0.9,0.2c0.5,0.3-0.7,1.6-0.8,2
        			c-0.1,0.4-0.1,0.4,0.3,0.2c0.2-0.1,0.5-0.2,0.7-0.3c0.4-0.2,0.8-0.5,1.2-0.7c0.2-0.1,0.5-1.3,0.6-1.6c-0.2,0-0.4-0.1-0.6-0.1
        			c-0.6-0.2,0-0.2-0.2-0.6c0.1-0.1,0.3-0.4,0.4-0.4c0.3-0.1,0.8,0.2,1,0c0.1-0.1,0.1-0.3,0.2-0.4c0.2-0.3,0.5-0.7,1-0.7l0.2,0.1
        			c0.1,0,0.2,0,0.3,0c0,0.2-0.1,0.2-0.3,0.3c-0.3,0.2-0.4,0.5-0.6,0.8c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0,0.7,0h0.1c0.2,0,0.5,0,0.6,0.1
        			v0.2c-0.3,0.5-1.1,0.5-1.7,0.6c-0.1,0-0.2,0-0.3,0l0,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.1,0.4-0.2,0.6c0,0.1-0.1,0.2-0.2,0.4
        			c-0.2,0.5-0.4,1.3-0.1,1.7c0.8-0.4,1.6-1.2,2.2-1.8c0.2-0.2,0.3-0.5,0.6-0.7c0.1-0.1,0.4-0.1,0.4,0c0.2,0.1,0.2,0.2,0.1,0.4
        			c-0.2,0.4-0.5,0.7-0.6,1.1c-0.1,0.4-0.1,0.6,0.1,1c0.2-0.1,0.4-0.3,0.6-0.4c0.7-0.4,1.4-0.8,2-1.4c0.1-0.1,0.2-0.2,0.3-0.3
        			c0.1-0.2,0.2-0.4,0.4-0.5l0.2-0.1l0.1-0.1c0.1,0,0.3,0,0.4,0c0.3,0.1-0.1,0.8-0.2,1c0,0.1,0,0.5-0.1,0.7V53c0,0.2,0,0.4,0,0.6
        			c0,0.2,0.2,0.4,0.1,0.6l0,0h-0.2l-0.2,0.2c-0.1,0.1-0.2,0.3-0.4,0.4c-0.2,0.1-0.3,0-0.4-0.1c-0.1,0-0.3,0-0.4-0.1
        			c-0.5-0.1-1.2-0.4-1.5-0.6c-0.4,0.3-0.9,0.7-1.5,0.6h-0.1L73,54.8c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.3,0.1-0.6,0-0.8
        			c-0.4,0.2-0.6,0.5-0.9,0.8c-0.3,0.2-0.5,0.4-0.8,0.6c-0.1-0.1-0.2,0-0.3,0c-0.6,0-0.6-0.9-0.5-1.4h-0.1h-0.1l-0.2,0.1
        			c-0.5,0.4-1,0.7-1.5,1c-0.2,0.1-0.5,0.4-0.8,0.5c-0.1,0-0.1,0-0.2,0C67.2,55.2,67.2,55.1,67.1,54.9 M75.8,53.6
        			c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.6,0.4,0.7,0.1c0.1-0.2,0-0.4,0-0.6c0-0.3,0.1-0.3,0-0.5C76.4,53.1,76.1,53.4,75.8,53.6"></path>
                  <path className="st0" d="M124.6,50.3c0.1-0.3,0.4-0.7,0.6-0.9c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.4-0.3,0.6-0.3c0.1,0,0.3,0.1,0.3,0.3
        			l-0.9,1.1l-0.3,0.1c-0.1,0.1-0.2,0.3-0.4,0.4h-0.3C124.7,50.5,124.6,50.4,124.6,50.3"></path>
                  <path className="st0" d="M108.8,54.3c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.1-0.4,0.3-0.5,0s0.3-1.2,0.4-1.4c0.5-1.1,1-2,1.8-2.9
        			c0.5-0.6,1-1.2,1.9-1.4c0.3-0.1,0.6-0.2,1-0.2c0.2,0,0.4,0.1,0.5,0.2c0.7,0.4-0.1,1.4-0.3,1.7l-0.2,0.2l0,0
        			c0.6,0.1,1.2-0.1,1.8,0.1l0.1,0.1c0.4,0.2,0.6,0.4,0.8,0.8c0.2,0.3,0.2,0.6,0.1,1c-0.2,0.9-1,1.3-1.7,1.7s-1.3,0.9-2.1,1
        			c-0.2,0-0.4,0-0.6,0C110.3,55.5,109.4,55.1,108.8,54.3 M109.8,53.4c0.4,0.1,0,0.3,0.2,0.4c0.3,0.2,0.6,0.4,0.9,0.6
        			c0.6,0.3,1.5,0.3,2.1,0.1c0.4-0.1,1-0.5,1.4-0.8c0.2-0.2,0.5-0.5,0.5-0.7c0.1-0.3-0.1-0.6-0.3-0.8c-0.6-0.8-1.9-0.6-2.8-0.4
        			c-0.2,0.1-0.4,0.3-0.6,0.2c-0.3-0.1,0-0.5,0.1-0.7c0.2-0.2,0.5-0.4,0.7-0.6c0.2-0.1,0.3-0.4,0.4-0.6c0.3-0.4,0.4-0.7-0.3-0.7
        			c-0.1,0-0.2,0.1-0.3,0.1c-1.2,0.4-2.1,2-2.5,3.2c-0.1,0.3-0.2,0.5-0.3,0.8c0.2,0,0.3-0.2,0.5-0.2
        			C109.7,53.4,109.7,53.4,109.8,53.4"></path>
                  <path className="st0" d="M63.8,55.6c0-0.4-0.1-0.9-0.1-1.2c0-0.4,0-0.6-0.1-1c-0.3,0-0.5,0.1-0.8,0.1c-0.3,0.1-1,0.1-1.4,0.2
        			c-0.1,0-0.2,0-0.4,0L60.9,54c-0.1,0.2,0,0.1,0,0.4c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.7,0.8-0.8,0.4c-0.1-0.3,0-0.5,0.1-0.7
        			s0.4-0.5,0.4-0.6c0-0.2-0.1-0.5,0-0.7l0,0l0.3-0.3c0.3-0.1,0.4,0.1,0.6-0.1c0.2-0.2,0.4-0.5,0.6-0.7c0.6-0.6,1.2-1.2,1.6-1.9
        			c0.1-0.2,0.3-0.3,0.4-0.5c0.2-0.2,0.2-0.5,0.5-0.7l0.1-0.1h0.1c0.4,0,0.4,0.3,0.3,0.6v0.1c-0.2,0.7-0.3,1.5-0.3,2.3
        			c0,0.2-0.1,0.4,0,0.7c0,0.3,0.4,0.1,0.5,0.4c-0.1,0.1-0.3,0.1-0.1,0.3l-0.3,0.2c-0.1,0.1-0.1,0.5-0.1,0.6c0,0.2,0.2,0.5,0.2,0.7
        			c0,0.1,0,0.2,0,0.3s0.1,0.1,0.1,0.3c0,0.1-0.2,0.1-0.3,0.2c-0.2,0.1-0.3,0.4-0.5,0.5h-0.1C64,55.7,63.9,55.6,63.8,55.6 M63.2,50.8
        			L63.1,51c-0.3,0.4-0.6,0.7-0.9,1.1c-0.1,0.2-0.2,0.3-0.3,0.5c0.6,0.1,1.2-0.1,1.7-0.1c0.1-0.5,0.2-1.3,0.2-1.8l0,0l0.1-0.2
        			l-0.1-0.1C63.6,50.5,63.3,50.6,63.2,50.8"></path>
                  <path className="st0" d="M60.2,49.9c0-0.2,0.1-0.3,0.2-0.4c0.2-0.1,0.4-0.4,0.5-0.6c0.2-0.5,0.4-0.9,1-1c0.2,0,0.4,0.1,0.5,0.2v0.1
        			c-0.2,0.2-0.4,0.4-0.6,0.6c-0.3,0.3-0.6,0.5-0.9,0.8l-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.1L60.3,50C60.2,50,60.2,50,60.2,49.9"></path>
                  <path className="st0" d="M56.1,54.9c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.3-0.8-0.2-1.2c0.1-0.6,0.2-1.2,0.5-1.7c0.4-1,2-4,3.2-4.1
        			c0.1,0,0.1,0.1,0.2,0.1h0.1c-0.1,0.3-0.1,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3,0.3-0.6,0.7-0.8,1.1c-0.5,0.6-1,1.3-1.2,2.2
        			c-0.1,0.4-0.2,0.7-0.3,1.2c0,0.1,0,0.2,0,0.4v0.2l0,0v0.1c-0.2,1.1,1.7-0.1,1.9-0.2c0.2,0,0.3-0.1,0.4,0c-0.2,0.5-0.9,0.9-1.4,1.2
        			C57.2,54.9,57,55,56.7,55C56.5,55,56.3,55,56.1,54.9"></path>
                  <path className="st0" d="M78.6,55.1h-0.2c-0.1-0.1-0.1-0.2-0.2-0.4c-0.4-1.2,1.4-3,2.2-3.4c0.3-0.2,0.9-0.2,1.2,0
        			c0.1,0.1,0.2,0.2,0.3,0.2c0.3,0,0.4-0.3,0.7-0.2c0.1,0,0.3,0.2,0.3,0.3l-0.1,0.1l-0.1,0.1l-0.1,0.1l0,0.1l-0.1,0.1
        			c-0.1,0.1-1.1,1.6-0.8,1.7c0.2,0.1,0.5-0.2,0.6-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.2,0.2-0.3,0.3-0.5c0.3-0.4,0.7-0.9,1-1.2
        			c0.1-0.1,0.4-0.4,0.5-0.4s0.3,0,0.3,0.1c0.3,0.2,0.3,0.6,0.1,0.9c-0.1,0.2-0.2,0.5-0.2,0.7c0.2-0.1,0.3-0.2,0.5-0.4
        			c0.3-0.3,0.6-0.8,1.2-0.7c0.3,0.1,0.3,0.1,0.3,0.4v0.1c0,0.2-0.1,1,0,1.1s0.3,0.1,0.4,0c0.5-0.1,0.7-0.6,0.9-1
        			c0.1-0.2,0.3-0.3,0.4-0.5c0.3-0.3,0.8-0.7,1.1-0.9c0.2-0.1,0.8-0.2,1.1-0.1c0.2,0.1,0.3,0.3,0.5,0.2s0.3-0.1,0.4,0l0.2,0.1v0.2
        			c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.3-0.3,0.6-0.4,1c0,0.1-0.1,0.3-0.1,0.5c0.6-0.1,1.3-0.7,1.7-1.1l0.2-0.2
        			c0.1-0.1,0.1-0.3,0.1-0.4c0.1-0.2,0.2-0.4,0.3-0.7c-0.3-0.1-0.5-0.1-0.7-0.2l0.1-0.1c0-0.1-0.1-0.2,0-0.3l0.2-0.2
        			c0.1-0.1,0.4-0.2,0.6-0.2c0.1,0,0.3,0,0.4,0c0.1-0.1,0.2-0.3,0.2-0.4c0.2-0.2,0.4-0.4,0.5-0.6c0.1-0.1,0.1-0.2,0.2-0.2
        			s0.4,0.1,0.6,0.1c-0.1,0.3-0.4,0.5-0.5,0.7c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0.1,0.3,0,0.5,0s0.4-0.1,0.6-0.1c0.4-0.1,0.7,0,1.1-0.1
        			c0.4,0.6-0.7,0.8-1.2,0.9c-0.4,0.1-1.3,0.1-1.6,0.3l0.2,0.7c-0.2,0.6-0.5,1.2-0.3,1.9V54c0.1,0.1,0.4,0,0.5,0
        			c0.2,0,0.3-0.1,0.5-0.1c0.1-0.1,0.9-0.8,1-0.3c0,0.1,0,0.3-0.1,0.3c-0.2,0.3-0.8,0.7-1.1,0.8L94,54.8l-0.2,0.1
        			c-0.2,0.1-0.7,0.3-1,0.2s-0.4-0.9-0.3-1.1v-0.1c-0.1-0.1-0.4,0.3-0.5,0.3c-0.3,0.3-1.6,1.4-2.1,0.7c-0.1-0.2,0-0.6-0.1-0.9
        			c-0.2,0.2-0.4,0.5-0.7,0.7c-0.4,0.3-0.8,0.5-1.4,0.4c-0.1,0-0.2,0-0.2-0.1c-0.2-0.2-0.2-0.5-0.2-0.7c-0.3,0.3-1,0.8-1.5,0.8
        			c-0.4,0-0.5-0.8-0.5-1.2l0.1-0.3l-0.1-0.1c-0.3,0.3-1.4,1.7-1.8,1.6l-0.2-0.2l0.1-0.6c0-0.2,0-0.4,0-0.6v-0.2L83,53.7
        			c-0.2,0.3-0.6,0.6-1,0.8h-0.1h-0.1h-0.1c-0.2,0.1-0.4,0.2-0.6,0.2c-0.5,0.1-0.4-0.5-0.4-0.8h-0.1c-0.6,0.3-1.1,1-1.9,1.1
        			L78.6,55.1 M80.6,52.1c-0.2,0-0.8,0.5-0.9,0.6c-0.1,0.2-0.9,1.3-0.5,1.5h0.2l0.3-0.2c0.5-0.3,0.9-0.7,1.3-1.2l0.3-0.3
        			c0-0.2-0.2-0.3-0.3-0.4C80.9,52.2,80.8,52.1,80.6,52.1L80.6,52.1 M89.8,52c-0.2,0-0.8,0.5-0.9,0.6c-0.1,0.2-0.9,1.3-0.5,1.5h0.2
        			l0.3-0.2c0.5-0.3,0.9-0.7,1.3-1.2l0.3-0.3c0-0.2-0.2-0.3-0.3-0.4C90.1,52.2,90,52,89.8,52L89.8,52"></path>
                </g>
                <polyline className="st1" points="13.8,47.3 13.8,32.9 26.3,40.1 26.3,54.6 13.8,47.3 		"></polyline>
                <polyline className="st0" points="26.3,40.1 38.8,32.9 38.8,47.3 26.3,54.6 26.3,40.1 		"></polyline>
                <polyline className="st0" points="40.1,32 27.5,24.8 51.4,11 51.4,40.1 40.1,46.5 40.1,32 		"></polyline>
                <path className="st1" d="M55.5,34.6c0-6.1,5-10.3,11.9-10.3c1.3,0,3.7,0.4,5.2,1L71.2,29c-1-0.3-2.8-0.4-3.4-0.4
        			c-2.9,0-5.9,1.9-5.9,6.3c0,4.3,3,6.2,6.2,6.2c1.3,0,2.5-0.2,3.4-0.5l1.2,3.7v0.1c-1.1,0.4-3,0.9-4.8,0.9
        			C61.1,45.3,55.5,41.3,55.5,34.6"></path>
                <path className="st1" d="M87,45l-1.6-4.8h-6.3L77.2,45h-4.8l8.3-20.3h5.1L93.5,45H87 M80.2,36.9h4.3c-0.7-2.4-1.3-4.3-2-7.2h-0.1
        			C81.7,32.6,81,34.5,80.2,36.9"></path>
                <path className="st1" d="M94,45V24.7h8.4c5,0,8.1,1.9,8.1,5.8c0,3.4-3.1,7.2-8.4,7.2H101v-3.6l0.8-0.1c0.8-0.2,2.8-0.9,2.8-3.2
        			c0-1.8-1.5-2.4-2.9-2.4h-1.6V45H94"></path>
                <polyline className="st1" points="111.4,45 111.4,24.7 123.7,24.7 123.7,28.5 117.5,28.5 117.5,32.5 123.5,32.5 123.5,36.3
        			117.5,36.3 117.5,41.2 124.9,41.2 124.9,45 111.4,45 		"></polyline>
                <path className="st1" d="M126.1,45V24.7h9c4,0,6.7,1.7,6.7,4.6c0,3.2-2.7,4.1-4,4.7l0,0c2.3,0.1,5.3,1.9,5.3,4.8
        			c0,3.7-2.9,6.2-8.7,6.2H126.1 M132.2,41.7h1.2c2.1,0,3.5-1.1,3.5-3c0-1.6-0.8-2.6-2.9-2.6h-1.7v5.6 M132.2,32.7h1.1
        			c2.2,0,3-1.3,3-2.6c0-1.7-1.5-2.2-2.4-2.2h-1.7L132.2,32.7"></path>
                <g className="border">
                  <polyline className="st1" points="44.5,65 33.6,58.8 142.3,58.8 142.3,65 44.5,65 		"></polyline>
                </g>
              </g>
            </svg>
              </div>

          <div className="p-4 md:p-7 flex bg-gray-100 rounded-lg dark:bg-gray-800">
          <img className="py-3 lg:py-5 w-16 h-auto m-auto md:w-20 lg:w-24 mx-auto" src="https://w7.pngwing.com/pngs/707/495/png-transparent-logo-norme-francaise-marque-nf-afnor-certification-standard-attaching-blue-text-trademark.png" />
           </div>
    
        </div>
      </div>
    </>
  )
}

export default Sponsors
