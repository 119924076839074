import Article from "../components/Article";
import Cards from "../components/Cards";
import Hero from "../components/Hero";
import LocalSite from "../components/LocalSite";
import Qualities from "../components/Qualities";
import Sponsors from "../components/Sponsors";
import Testimonials from "../components/Testimonials";

const MainPage = () => {
    return <>
        <Hero />
        <hr />
        <Qualities />
        <hr />
        <Cards />
        <hr />
        <Article />
        <hr />
        <LocalSite />
        <hr />
        <Testimonials />
        <hr />
        <Sponsors />
    </>;
  };
  
  export default MainPage;
  