const Article = () => {
  return <>
  
<div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
  <div className="max-w-2xl mx-auto">
    <div className="grid gap-12">
      <div>
        <h2 className="text-3xl text-gray-800 font-bold lg:text-4xl dark:text-white">
        Notre vision
        </h2>
        <p className="mt-3 text-gray-800 dark:text-gray-400">
          2AH vous propose de profiter de son savoir faire: 
          Isolation, Couverture, Gouttière, traitement des bois, Habillage des planches de rives ,
          Sarking </p>
      </div>

      <div className="space-y-6 lg:space-y-10">
        <div className="flex">
          <svg className="flex-shrink-0 mt-2 size-6 text-red-600 dark:text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M6 22V4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v18Z"/><path d="M6 12H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2"/><path d="M18 9h2a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-2"/><path d="M10 6h4"/><path d="M10 10h4"/><path d="M10 14h4"/><path d="M10 18h4"/></svg>
          <div className="ms-5 sm:ms-8">
            <h3 className="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
              Des services de haute qualité
            </h3>
            <p className="mt-1 text-gray-600 dark:text-gray-400">
            Nos prestations de toiture sont conçues et réalisées sur mesure, en fonction de vos besoins et de vos envies. Nous utilisons des matériaux de qualité, qui respectent les normes Qualibat et RGE, et qui vous garantissent une performance énergétique optimale et une longévité accrue. </p>
          </div>
        </div>

        <div className="flex">
          <svg className="flex-shrink-0 mt-2 size-6 text-green-800 dark:text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"/><circle cx="9" cy="7" r="4"/><path d="M22 21v-2a4 4 0 0 0-3-3.87"/><path d="M16 3.13a4 4 0 0 1 0 7.75"/></svg>
          <div className="ms-5 sm:ms-8">
            <h3 className="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
              Avançons ensemble
            </h3>
            <p className="mt-1 text-gray-600 dark:text-gray-400">
            Notre passion est de vous accompagner dans la valorisation de votre patrimoine. Au-delà de créer de beaux espaces de toiture, nous vous offrons un service personnalisé et de proximité.
            </p>
          </div>
        </div>

        <div className="flex">
          <svg className="flex-shrink-0 mt-2 size-6 text-blue-800 dark:text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M7 10v12"/><path d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2h0a3.13 3.13 0 0 1 3 3.88Z"/></svg>
          <div className="ms-5 sm:ms-8">
            <h3 className="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
            Simple et tout compris
            </h3>
            <p className="mt-1 text-gray-600 dark:text-gray-400">
            Nous nous occupons de tout, pour que vous n’ayez rien à faire. De la demande de devis en ligne à la facturation claire et transparente, nous vous simplifions la vie et vous assurons une totale satisfaction. </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  
  </>;
};

export default Article;
