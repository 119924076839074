export default function AskDevisButton() {
    return (
        <>
            <div className="sticky bottom-6 inset-x-0 text-center">
                <div className="inline-block bg-white shadow-md rounded-full border-dotted border-2 border-indigo-600 py-3 px-4 dark:bg-gray-800">
                    <div className="flex items-center gap-x-1.5">

                        <div className="hs-tooltip inline-block">
                            <a href="devis" type="button" className="font-bold hs-tooltip-toggle flex items-center gap-x-2 text-sm text-blue-500  hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                                <span className="material-symbols-outlined">
                                    task
                                </span>
                                Devis gratuit
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}